<template>
  <div class="max-vertical-height">
    <breadcrumbs :title="product.name"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: product.name, route: 'Product', params: { link: store.link, id: product.id }}]">
    </breadcrumbs>
    <div class="main_content">
      <div class="section page-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-12 col-12">
              <div class="row">
              <div class="col-lg-5 col-md-4 mb-4 mb-md-0">
                <div class="product-image">
                  <div class="product_img_box">
                    <img id="product_img" :src='productImageUri(product)' alt="product_img1" />
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-md-8">
                <div class="pr_detail">
                  <div class="product_description">
                    <h4 class="product_title">{{ product.name }}</h4>
                    <div>
                      <span class="price">${{ price.toFixed(2) }}</span>
                    </div>
                    <div class="pr_desc">
                      <p>{{ product.description }}</p>
                    </div>
                    <div class="product_sort_info">
                      <ul v-if="product.callouts && product.callouts.length > 0">
                        <li v-for="callout in product.callouts" :key="callout">
                          <i class="linearicons-shield-check"></i><span v-html="callout"></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
              <div class="row">
                <div class="col-12">
                  <div class="tab-style3">
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="add-info-tab" data-toggle="tab" href="#AddInfo" role="tab" aria-controls="AddInfo" aria-selected="true">Add Information</a>
                      </li>
                    </ul>
                    <div class="tab-content shop_info_tab">
                      <div class="tab-pane fade show active" id="AddInfo" role="tabpanel" aria-labelledby="add-info-tab">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="field_form">
                              <fieldset>
                                <div class="row">
                                  <div class="form-group chek-form col-md-12"
                                    v-if="product.bCanDonate &&
                                      store.typeShipToHome !== 2 &&
                                      !bShipToHome">
                                    <div class="custome-checkbox">
                                      <input class="form-check-input" type="checkbox" id="donateCheckbox" name="donateCheckbox" v-model="donate">
                                      <label class="form-check-label" for="donateCheckbox"><span>Donate this yearbook?</span></label>
                                    </div>
                                  </div>
                                  <template v-if="!donate">
                                    <div class="form-group col-md-12">
                                      <input required placeholder="Enter student first name *" class="form-control" v-model="student.firstName" name="firstName" type="text">
                                      <!-- <p v-if="personalized" class="product-help-text">As you would like it to appear on the cover</p> -->
                                      <small class="validation-error-text" v-if="$v.$anyError && !$v.student.firstName.required">First name is required</small>
                                      <small class="validation-error-text" v-if="$v.$anyError && !$v.student.firstName.maxLength">First name cannot be more than 100 characters</small>
                                    </div>
                                    <div class="form-group col-md-12">
                                      <input required placeholder="Enter student last name *" class="form-control" v-model="student.lastName" name="lastName" type="lastName">
                                      <!-- <p v-if="personalized" class="product-help-text">As you would like it to appear on the cover</p> -->
                                      <small class="validation-error-text" v-if="$v.$anyError && !$v.student.lastName.required">Last name is required</small>
                                      <small class="validation-error-text" v-if="$v.$anyError && !$v.student.lastName.maxLength">Last name cannot be more than 100 characters</small>
                                    </div>
                                    <template v-if="hasTeacherGradeCombinations && !commencement">
                                      <div class="form-group col-md-12 mt-1 mb-3">
                                        <label class="vs-input--label" v-if="account.typeAccount === 6">Grade / Homeroom</label>
                                        <label class="vs-input--label" v-else>Grade / Teacher</label>
                                        <div class="custom_select">
                                          <select class="form-control" v-model="selectedTgc">
                                            <option value="" disabled v-if="account.typeAccount === 6">Select a grade and homeroom</option>
                                            <option value="" disabled v-else>Select a grade and teacher</option>
                                            <option v-for="tgc in teacherGradeCombinations" :value="tgc.value" :key="tgc.value">{{ tgc.name }}</option>
                                          </select>
                                          <small class="validation-error-text" v-if="teacherGradeCombinationError">Grade and <span v-if="account.typeAccount === 6">homeroom</span><span v-else>teacher</span> is required</small>
                                        </div>
                                        <span></span>
                                      </div>
                                    </template>
                                    <template v-if="(!hasTeacherGradeCombinations || (hasTeacherGradeCombinations && tgcOtherSelected)) && !commencement">
                                      <div class="form-group col-md-12">
                                        <input required :placeholder="account.typeAccount === 6 ? 'Enter homeroom *' : 'Enter teacher *'" class="form-control" v-model="student.teacher" name="teacher">
                                        <p class="product-help-text">If staff, enter Staff. If not applicable, enter None.</p>
                                        <small class="validation-error-text" v-if="$v.$anyError && !$v.student.teacher.required"><span v-if="account.typeAccount === 6">Homeroom</span><span v-else>Teacher name</span> is required</small>
                                        <small class="validation-error-text" v-if="$v.$anyError && !$v.student.teacher.maxLength"><span v-if="account.typeAccount === 6">Homeroom</span><span v-else>Teacher name</span> cannot be more than 100 characters</small>
                                      </div>
                                      <div class="form-group col-md-12">
                                        <input placeholder="Grade *" class="form-control" v-model="student.grade" name="grade">
                                        <small class="validation-error-text" v-if="$v.$anyError && !$v.student.grade.required">Grade is required</small>
                                        <small class="validation-error-text" v-if="$v.$anyError && !$v.student.grade.maxLength">Grade cannot be more than 100 characters</small>
                                      </div>
                                    </template>
                                    <template v-if="product.bPersonalized && personalizationActive">
                                      <div class="form-group chek-form col-md-12">
                                        <div class="custome-checkbox">
                                          <input class="form-check-input" type="checkbox" id="personalizedCheckbox" name="personalized" v-model="personalized">
                                          <label class="form-check-label" for="personalizedCheckbox"><span>Personalize cover?</span></label>
                                        </div>
                                      </div>
                                      <template v-if="personalized">
                                        <div class="col-md-12" style="height: 35px; margin-top: -5px;;">
                                          <p style="color: green" v-if="product.typePersonalization === 1">Name (+${{ Number(product.personalizationNameCost).toFixed(2) }})</p>
                                          <p style="color: green" v-if="product.typePersonalization === 2">Name and Photo (+${{ Number(product.personalizationNameAndPhotoCost).toFixed(2) }})</p>
                                          <p style="color: green" v-if="product.typePersonalization === 4">Name and Icons (+${{ (Number(product.personalizationNameAndIconsCost) + (selectedIcons - 1) * Number(product.additionalIconCost)).toFixed(2) }})</p>
                                        </div>
                                        <div class="form-group chek-form col-md-12">
                                          <p style="color: red; margin-bottom: 5px;">What should be printed on cover<span v-if="product.typePersonalization === 4"> in addition to icons</span>?</p>
                                          <input class="form-radio-input" id="first_and_last" type="radio" name="what_on_cover" :value="1" v-model="whatsOnCover">
                                          <label for="first_and_last" style="margin-left: 5px;">First and last name</label><br>
                                          <input class="form-radio-input"  id="custom" type="radio" name="what_on_cover"  :value="2" v-model="whatsOnCover">
                                          <label for="custom" style="margin-left: 5px;">Custom (nickname, etc...)</label><br>
                                        </div>
                                        <div class="form-group col-md-12" v-if="whatsOnCover === 2" style="margin-top: -10px;">
                                          <input placeholder="Enter custom personalization *" class="form-control" v-model="student.personalizedName" name="personalizedName">
                                          <!-- <p v-if="personalized" class="product-help-text">As you would like it to appear on the cover</p> -->
                                          <p :class="!$v.student.personalizedName.maxLength ? 'validation-error-text' : ''" style="margin-bottom: 5px;">{{ student.personalizedName.length }}/50</p>
                                          <small class="validation-error-text" v-if="whatsOnCover === 2 && whatsOnCoverValidationError">This field is required</small>
                                          <small class="validation-error-text" v-if="$v.$anyError && !$v.student.personalizedName.maxLength">Maximum 50 characters</small>
                                        </div>
                                        <div class="form-group col-md-12" v-if="product.typePersonalization === 4" style="margin-top: 15px;">
                                          <a href="https://s3.amazonaws.com/yearbookmarket.desk/Icons.png" target="_blank">
                                            <button class="btn btn-small btn-fill-out" style="text-transform: none; padding: 6px; margin-bottom: 8px; font-size: 14px;">Click to Open Icon List</button>
                                          </a>
                                          <p class="product-help-text">Number of icons to add</p>
                                          <select class="form-control mb-3" v-model.number="selectedIcons">
                                            <option value="1" v-if="product.maximumNumberOfIcons > 0">1</option>
                                            <option value="2" v-if="product.maximumNumberOfIcons > 1">2</option>
                                            <option value="3" v-if="product.maximumNumberOfIcons > 2">3</option>
                                            <option value="4" v-if="product.maximumNumberOfIcons > 3">4</option>
                                          </select>
                                          <input required placeholder="Icon one *" class="form-control mb-3" v-model="iconOne" name="iconOne" v-if="selectedIcons > 0">
                                          <p class="product-help-text" style="margin-top: -15px;" v-if="selectedIcons === 1">Select an icon or enter 'Name only'</p>
                                          <input required placeholder="Icon two *" class="form-control mb-3" v-model="iconTwo" name="iconTwo" v-if="selectedIcons > 1">
                                          <input required placeholder="Icon three *" class="form-control mb-3" v-model="iconThree" name="iconThree" v-if="selectedIcons > 2">
                                          <input required placeholder="Icoun four *" class="form-control mb-3" v-model="iconFour" name="iconFour" v-if="selectedIcons > 3">
                                          <small class="validation-error-text" v-if="iconValidationError">Missing required icon field</small>
                                        </div>
                                      </template>
                                    </template>
                                    <div class="form-group col-md-12" v-if="product.typeProduct === 3">
                                      <!-- Free text field, or cover and icons (icons are numeric and comma separated) -->
                                      <textarea id="message" placeholder="Message *" class="form-control" name="message" rows="4" v-model="message"></textarea>
                                      <small class="validation-error-text" v-if="messageValidationError">A message is required<br></small>
                                      <small>Note: Please do not include emojis in your message since they cannot be copied into yearbook design software.</small>
                                    </div>
                                  </template>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                          <div class="col-lg-12 pt-2 pt-lg-0 mt-0 mt-lg-0">
                            <div class="cart_extra">
                              <h4 class="mr-3 mt-2" style="color: green">${{ (itemPrice * quantity).toFixed(2) }}</h4>
                              <div class="cart-product-quantity">
                                <div class="quantity">
                                  <input type="button" value="-" class="minus" @click="quantity = quantity === 1 ? quantity : quantity - 1">
                                  <input type="text" disabled name="quantity" v-model="quantity" title="Qty" class="qty" size="4">
                                  <input type="button" value="+" class="plus" @click="quantity = quantity + 1">
                                </div>
                              </div>
                              <div class="cart_btn">
                                <button class="btn btn-fill-out btn-addtocart" type="button" @click="addItemToCart">
                                  <i class="icon-basket-loaded"></i> Add to cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#product_img {
  max-height: 200px;
}
.product_img_box {
  text-align: center;
  padding: 10px;
}
.product-help-text {
  font-size: 12px;
  margin: 0px;
  margin-left: 3px;
  margin-top: -3px;
}
.shop_info_tab {
  margin-top: 20px !important;
}
.vs-notifications {
  color: white !important;
}
.con-text-noti h3 {
  margin-bottom: 0px !important;;
  color: white !important;
}
.con-text-noti p {
  margin-bottom: 10px !important;
  color: white !important;

}
</style>

<script>
import to                     from '@/utils/to'
import { mapGetters }         from 'vuex'
import { storeCommonMixin }   from '@/mixins/storeCommon'
import Breadcrumbs            from '@/components/layout/header/Breadcrumbs'
import Repository             from '@/repositories/RepositoryFactory'
const ProductRepository       = Repository.get('products')
const ProjectRepository       = Repository.get('projects')
const TeacherGradeCombinationRepository = Repository.get('teacher_grade_combinations')
const { required, maxLength } = require('vuelidate/lib/validators')

let $ = window.$

export default {
  name: 'Product',
  components: { Breadcrumbs },
  mixins: [storeCommonMixin],
  computed: {
    ...mapGetters([
      'account',
      'cart',
      'store'
    ]),
    itemPrice () {
      let iPrice = this.price
      if (this.personalized) {
        if (this.product.typePersonalization === 1)
          iPrice += Number(this.product.personalizationNameCost)
        else if (this.product.typePersonalization === 2)
          iPrice += Number(this.product.personalizationNameAndPhotoCost)
        else if (this.product.typePersonalization === 4)
          iPrice += (Number(this.product.personalizationNameAndIconsCost) + (this.selectedIcons - 1) * Number(this.product.additionalIconCost))
      }
      return iPrice
    },
    personalizationCost () {
      if (this.product.typePersonalization === 1)
        return Number(this.product.personalizationNameCost)
      else if (this.product.typePersonalization === 2)
        return Number(this.product.personalizationNameAndPhotoCost)
      else if (this.product.typePersonalization === 4)
        return (Number(this.product.personalizationNameAndIconsCost) + (this.selectedIcons - 1) * Number(this.product.additionalIconCost))
      return 0
    },
    expired () {
      let price = this.getProductPrice(this.product, this.account.timezone)
      return price === -1
    },
    hasTeacherGradeCombinations () {
      return this.teacherGradeCombinations.length > 0
    },
    personalizationActive () {
      let active = false
      if (this.product.bPersonalized) {
        if (!this.product.datePersonalizationExpires) { // No expiration date set
          active = true
        } else if (!this.isDateInThePast(this.product.datePersonalizationExpires, this.account.timezone)) {
          // Expiratation date is in the future
          active = true
        }
      }
      return active
    }
  },
  data () {
    return {
      product  : {},
      price    : 0,
      student  : {
        firstName : '',
        lastName  : '',
        teacher   : '',
        grade     : '',
        personalizedName: ''
      },
      project      : null,
      personalized : false,
      bShipToHome  : false,
      message      : '',
      messageValidationError : false,
      quantity     : 1,
      donate       : false,
      commencement : false,
      // Teacher / Grade Combinations
      teacherGradeCombinations     : [],
      selectedTgc                  : '',
      tgcOtherSelected             : false,
      teacherGradeCombinationError : false,
      selectedIcons                : 1,
      iconOne                      : '',
      iconTwo                      : '',
      iconThree                    : '',
      iconFour                     : '',
      iconValidationError          : false,
      whatsOnCover                 : 1,
      whatsOnCoverValidationError  : false
    }
  },
  validations: {
    student: {
      firstName: {
        required,
        maxLength: maxLength(100)
      },
      lastName: {
        required,
        maxLength: maxLength(100)
      },
      teacher: {
        required,
        maxLength: maxLength(100)
      },
      grade: {
        required,
        maxLength: maxLength(100)
      },
      personalizedName: {
        maxLength: maxLength(50)
      }
    }
  },
  watch: {
    donate (val) {
      if (val) {
        this.student.firstName = 'Donate'
        this.student.lastName  = 'Donate'
        this.student.teacher   = 'Donate'
        this.student.grade     = 'Donate'
        this.student.personalizedName = ''
        this.personalized = false
        this.bShipToHome  = false
        this.teacherGradeCombinationError = false
        this.iconValidationError = false
        this.whatsOnCoverValidationError = false
      } else {
        this.student.firstName = ''
        this.student.lastName  = ''
        this.student.teacher   = ''
        this.student.grade     = ''
        this.student.personalizedName = ''
        // If unchecking donate, set personalized to true if active and autoselect is turned on
        if (this.personalizationActive && this.product.bAutoselectPersonalization) {
          this.personalized = true
        } else {
          this.personalized = false
        }
        this.bShipToHome  = false
      }
    },
    selectedTgc (val) {
      if (val === 'Other') this.tgcOtherSelected = true
      else this.tgcOtherSelected = false
    },
    commencement (val) {
      if (val) {
        this.student.teacher = 'Commencement'
        this.student.grade = 'Commencement'
      }
    }
  },
  methods: {
    /**
     * Gets product information
     * @param store - Store object
     * @param account - Account object
     */
    getProduct: async function (store, account) {
      if (!store.id) {
        this.$router.push({ name: 'Store', params: { link: this.$route.params.link }})
        throw new Error('Store not in session when product loaded.')
      }
      let err, product;
      [err, product] = await to(ProductRepository.getProduct(this.$route.params.id, { include: ['prices', 'store'] }))
      if (!product || product.data.length === 0) {
        this.$router.push({ name: 'Store', params: { link: this.$route.params.link }})
        throw new Error(err)
      } else if (product.data[0].storeId !== store.id || product.data[0].store.accountId != account.id) {
        console.error(`Product store and account did not match session: Product: ${product.data[0].storeId} | ${product.data[0].store.accountId}, Session: ${store.id} | ${account.id}`)
        window.location.href = `/${this.$route.params.link}`
      }
      this.product = product.data[0]
      this.price = this.getProductPrice(this.product, account.timezone)
      if (this.product.typeProduct === 3 && this.product.maxCharacterCount > 0) {
        let self = this
        setTimeout(function () {
          $('#message').textcounter({
            type                  : 'character',
            max                   : Number(self.product.maxCharacterCount),
            stopInputAtMaximum    : true,
            countContainerElement : 'div',
            countContainerClass   : 'text-count-wrapper',
            counterText           : '(%d/' + Number(self.product.maxCharacterCount) + ')',
            countSpaces           : true
          })
        }, 1000)
      }
      if (this.personalizationActive && this.product.bAutoselectPersonalization) this.personalized = true // Auto check personalization if active and autoselected
      await this.getTeacherGradeCombinations(account.id)
      // Load project information if shippable
      if (this.product.typeProduct === 1 // Yearbook product
        && (this.product.typeShipping === 2 || this.store.typeShipToHome === 2)) // Either summer single, or mandatory ship-to-home
        await this.getProject(account.id, this.product.year, this.product.typeBook)
      // Check if routed directly to donate
      if (this.$route.query.donate && this.product.bCanDonate && this.store.typeShipToHome !== 2) this.donate = true
      // Check if a SchoolPictures.com commencement program
      if (this.account.parentId === 'LDUR31' && this.product.name.toLowerCase().includes('commencement')) {
        this.commencement = true
      } else {
        this.commencement = false
      }
      this.hideLoader($)
    },
    /**
     * Get teacher grade combinations
     */
    getTeacherGradeCombinations: async function (accountId) {
      let err, tgc;
      [err, tgc] = await to(TeacherGradeCombinationRepository.get({ accountId: accountId, order: 'grade ASC' }))
      if (!tgc) throw new Error(err)
      if (tgc.data.length > 0) {
        this.teacherGradeCombinations = tgc.data.map(function (t) { return { name: `${t.grade} / ${t.teacher}`, value: `${t.grade}|${t.teacher}` }})
        this.teacherGradeCombinations.push({ name: 'Other (not listed)', value: 'Other' })
      }
    },
    /**
     * Gets associated yearbook project for calculating ship-to-home costs
     */
    async getProject (accountId, year, typeBook) {
      let err, projects;
      [err, projects] = await to(ProjectRepository.get({
        accountId: accountId,
        year: year,
        typeBook: typeBook}))
      if (!projects || projects.data.length === 0) {
        this.$vs.notify({
          color    : 'danger',
          title    : 'Warning',
          text     : 'This product is not configured properly to ship-to-home. We have been contacted and will work with your school to resolve the issue.',
          position : 'top-center',
          time     : 5000
        })
        const self = this
        setTimeout(function () {
          self.$router.push({ name: 'Store', link: self.store.link })
          self.hideLoader($)
          throw new Error(`The product ID ${self.product.id} for ${self.product.year} is not configured properly to ship-to-home for ${self.account.name}. Err: ${err}`)
        })
      } else {
        this.project = projects.data[0]
      }
    },
    /**
     * Adds item to cart
     */
    addItemToCart () {
      // Validate teacher / grade selection if product is not donated and is not for a commencement
      if (!this.donate && !this.commencement && this.hasTeacherGradeCombinations) {
        if (!this.selectedTgc) this.teacherGradeCombinationError = true
        else {
          this.teacherGradeCombinationError = false
          if (!this.tgcOtherSelected) { // Actual combo selected
            let combo = this.selectedTgc.split('|')
            this.student.grade = combo.length > 0 ? combo[0] : ''
            this.student.teacher = combo.length > 1 ? combo[1] : ''
          }
        }
      }
      this.$v.$touch()

      // Check message validation
      if (this.product.typeProduct === 3 && this.message.length === 0) this.messageValidationError = true
      else this.messageValidationError = false

      // Check icon validation
      if (this.personalized && this.product.typePersonalization === 4) {
        if (this.selectedIcons === 4 && ($.trim(this.iconOne) === '' || $.trim(this.iconTwo) === '' || $.trim(this.iconThree) === '' || $.trim(this.iconFour) === '')) {
          this.iconValidationError = true
        } else if (this.selectedIcons === 3 && ($.trim(this.iconOne) === '' || $.trim(this.iconTwo) === '' || $.trim(this.iconThree) === '')) {
          this.iconValidationError = true
        } else if (this.selectedIcons === 2 && ($.trim(this.iconOne) === '' || $.trim(this.iconTwo) === '')) {
          this.iconValidationError = true
        } else if (this.selectedIcons === 1 && $.trim(this.iconOne) === '') {
          this.iconValidationError = true
        } else {
          this.message = this.iconOne + ''
          this.message += this.selectedIcons > 1 ? `; ${this.iconTwo}` : ''
          this.message += this.selectedIcons > 2 ? `; ${this.iconThree}` : ''
          this.message += this.selectedIcons > 3 ? `; ${this.iconFour}` : ''
          this.iconValidationError = false
        }
      }

      if (this.personalized && this.whatsOnCover === 2) {
        if ($.trim(this.student.personalizedName) === '' || !this.student.personalizedName) this.whatsOnCoverValidationError = true
        else this.whatsOnCoverValidationError = false
      } else {
        this.whatsOnCoverValidationError = false
      }

      if (this.personalized && this.whatsOnCover === 1) {
        this.student.personalizedName = `${this.student.firstName} ${this.student.lastName}`
      }

      if (this.$v.$invalid || this.messageValidationError || this.teacherGradeCombinationError || this.iconValidationError || this.whatsOnCoverValidationError) {
          this.$vs.notify({
            color    : 'danger',
            title    : 'Warning',
            text     : 'Missing or required fields require attention',
            position : 'top-right'
          })
      } else {
        if (this.checkCrossStoreOrder(this.store.id, this.cart, this.product.storeId)) return
        if (this.checkCrossShippingOrder(this.product.typeShipping, this.cart)) return
        if (this.checkSummerSingleSameYear(this.product.typeShipping, this.product.year, this.cart)) return
        if (this.expired) {
          this.$vs.notify({
            color    : 'danger',
            title    : 'Warning',
            text     : 'This product has expired and cannot be ordered at this time.',
            position : 'top-right'
          })
          return
        }
        // Set type shipping if this is a bulk ship to home and shipping selected or non-optional
        if (this.bShipToHome ||
          (this.product.typeProduct === 1 && this.product.typeShipping === 1 && this.store.typeShipToHome === 2))
          this.product.typeShipping = 3
        let item = {
          donated: this.donate,
          itemPrice: this.itemPrice,
          message: this.message,
          personalized: this.personalized,
          personalizationCost: this.personalizationCost,
          product: this.product,
          productPrice: this.price,
          project: this.project,
          quantity: this.quantity,
          storeId: this.store.id,
          storeLink: this.store.link,
          student: this.student,
          typePersonalization: this.product.typePersonalization
        }
        this.$store.dispatch('addItemToCart', item)
        this.$vs.notify({
          color    : 'success',
          title    : 'Added to Cart',
          text     : this.donate ? `Thank you for donating ${this.quantity === 1 ? 'a yearbook!' : this.quantity + ' yearbooks!'}` : 'The product has been added to your cart',
          position : 'top-right'
        })
        this.$router.push({ name: 'Store', params: { link: this.store.link }})
      }
    }
  },
  created () {
    this.showLoader($)
    this.getProduct(this.store, this.account)
  }
}
</script>
