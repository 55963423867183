<template>
  <div class="max-vertical-height">
    <breadcrumbs :title="account.name"
      :crumbs="[]"></breadcrumbs>
    <div class="main_content">
      <div class="section small_pt pb_20 current-year-section current-and-prior-year-sections">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="heading_s3 text-center current-year-header">
                <h2>Current Year Products</h2>
              </div>
              <div class="small_divider clearfix"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" v-if="currentYearProducts.length > 0 || dedicationAdProducts.length > 0">
              <carousel :perPageCustom="[[0, 1], [481, 2], [768, 3], [1199, 4]]"
                :scrollPerPage="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                navigationNextLabel="&rsaquo;"
                navigationPrevLabel="&lsaquo;">
                <slide v-for="(product, i) in currentYearProducts" :index="i" :key="product.id">
                  <product-item :link="store.link" :product="product" />
                </slide>
                <!-- GENERATES SLIDE WITH OPTION TO BUILD AN AD -->
                <slide v-if="offersAdBuilding && dedicationAdProducts.length > 0">
                  <product-item :link="store.link" :product="{}" :canBuildAdStore="true" :offersKidsPages="offersKidsPages" />
                </slide>
              </carousel>
            </div>
            <div v-else class="col-md-12 none-available" v-html="noProductsAvailableMessage" />
          </div>
        </div>
      </div>
      <div class="section small_pt pb_20 prior-year-section current-and-prior-year-sections" v-if="previousYearbookProducts.length > 0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="heading_s3 text-center prior-year-header">
                <h2>Prior Year Yearbooks</h2>
              </div>
              <div class="small_divider clearfix"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" v-if="previousYearbookProducts.length > 0">
              <carousel :perPageCustom="[[0, 1], [481, 2], [768, 3], [1199, 4]]"
                :scrollPerPage="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                navigationNextLabel="&rsaquo;"
                navigationPrevLabel="&lsaquo;">
                <slide v-for="(product, i) in previousYearbookProducts" :index="i" :key="product.id">
                  <product-item :link="store.link" :product="product" />
                </slide>
              </carousel>
            </div>
            <div v-else class="col-md-12 none-available">
              None Available
            </div>
          </div>
        </div>
      </div>
      <div class="section small_pb value-add-section">
        <div class="container">
          <div class="row no-gutters">
            <div class="col-lg-4 col-sm-4">
              <div class="icon_box icon_box_style3">
                <div class="icon">
                  <i class="flaticon-lock"></i>
                </div>
                <div class="icon_box_content">
                  <h6>Secure Payments</h6>
                  <p>SSL Security and PCI Compliant</p>
                  <p></p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <a href="https://yearbookmarket.helpscoutdocs.com/" target="_blank" rel="noopener noreferrer">
                <div class="icon_box icon_box_style3">
                  <div class="icon">
                    <i class="flaticon-support"></i>
                  </div>
                  <div class="icon_box_content">
                    <h6>Need help?</h6>
                    <p>Consult our FAQ and contact support team</p>
                    <p></p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div class="icon_box icon_box_style3">
                <div class="icon">
                  <i class="flaticon-money-back"></i>
                </div>
                <div class="icon_box_content">
                  <h6>No-hassle Refunds</h6>
                  <p style="margin-bottom: 0px;">Ordered Twice? No Problem!</p>
                  <small>* See our terms and conditions.</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters }      from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import ProductItem         from '@/components/pages/partials/ProductItem'
import Breadcrumbs         from '@/components/layout/header/Breadcrumbs'

export default {
  name: 'Store',
  components: {
    Carousel,
    Slide,
    ProductItem,
    Breadcrumbs
  },
  computed: {
    ...mapGetters([
      'account',
      'currentYearProducts',
      'dedicationAdProducts',
      'offersAdBuilding',
      'offersKidsPages',
      'previousYearbookProducts',
      'store',
      'dateStoreOpens',
      'dateStoreClosed'
    ]),
    noProductsAvailableMessage () {
    // has currrent school year products but the store has not opened yet
     if (this.dateStoreOpens) {
        const firstStartDate = this.dateStoreOpens.format('MMMM Do, YYYY')
        return `Sales commence on ${firstStartDate}.<br>Please return to the store at this time to make your purchase.`
      }
      // has currrent school year products but the deadline has passed
      else if (this.dateStoreClosed) {
        const lastDeadline = this.dateStoreClosed.format('MMMM Do, YYYY')
        return `Online sales concluded on ${lastDeadline}.<br>Please contact your school to inquire if other sales opportunities will be offered. Unfortunately, the Yearbook Market support team does not have the authority to offer sales date extensions or special orders outside the sales period set by your school.`
      } else {
        return 'The store has not been set up for the 2024-2025 school year.<br>Please check back later.'
      }
    }
  }
}
</script>
