import Vue       from 'vue'
import VueRouter from 'vue-router'

import Cart               from '@/views/pages/Cart'
import Checkout           from '@/views/pages/Checkout'
import Product            from '@/views/pages/Product'
import Store              from '@/views/pages/Store'
import Stores             from '@/views/pages/Stores'
import FAQ                from '@/views/pages/FAQ'
import TermsAndConditions from '@/views/pages/TermsAndConditions'
import OrderConfirmation  from '@/views/pages/OrderConfirmation'
import OrderLookup        from '@/views/pages/OrderLookup'

// Dedication ad pages
import AdLogin from '@/views/pages/ads/AdLogin'
import Ads     from '@/views/pages/ads/Ads'
import Ad      from '@/views/pages/ads/Ad'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Stores',
    component: Stores
  },
  {
    path: '/amilestonegroup',
    name: 'Stores',
    component: Stores
  },
  {
    path: '/wilcoxrecognition',
    name: 'Stores',
    component: Stores
  },
  {
    path: '/:link',
    name: 'Store',
    component: Store
  },
  {
    path: '/:link/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/:link/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/:link/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/:link/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/:link/products/:id',
    name: 'Product',
    component: Product
  },
  {
    path: '/:link/ads/login',
    name: 'AdLogin',
    component: AdLogin
  },
  {
    path: '/:link/ads',
    name: 'Ads',
    component: Ads
  },
  {
    path: '/:link/ads/:id',
    name: 'Ad',
    component: Ad
  },
  {
    path: '/:link/orders/:transactionId/:orderId',
    name: 'OrderConfirmation',
    component: OrderConfirmation
  },
  {
    path: '/:link/order-lookup',
    name: 'OrderLookup',
    component: OrderLookup
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
