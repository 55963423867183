<template>
  <div class="max-vertical-height">
    <breadcrumbs title="Order Confirmation"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: 'Cart', route: 'Cart', params: { link: store.link } },
        { name: 'Order Confirmation', route: 'OrderConfirmation', params: { link: store.link } }]">
    </breadcrumbs>
    <div class="main_content">
      <div class="section page-section">
        <div class="container">
          <div class="row" style="margin-bottom: 25px;">
            <div class="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12 col-12">
              <div class="text-center order_complete">
                <i class="fas fa-check-circle"></i>
                <h3>Your Order is Complete!</h3>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 50px;">
            <div class="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-sm-12 col-12" style="text-align: right;">
              <div>
                <div class="heading_s1 mb-0">
                  <h6>Order No.</h6>
                </div>
                1{{ order.id }}<br><br>
                <div class="heading_s1 mb-0">
                  <h6>Order Information</h6>
                </div>
                {{ order.billingFname }} {{ order.billingLname }}<br>
                {{ order.billingAddress }} {{ order.billingAddress2 }}<br>
                {{ order.billingCity }}, {{ order.billingState }} {{ order.billingZip }}<br>
                <a :href="'mailto:'+order.billingEmail">{{ order.billingEmail }}</a><br>
                P: <a :href="'tel:'+order.billingEmail">{{ order.billingPhone }}</a>
                <div class="heading_s1 mt-3 mb-0">
                  <h6>Shipping Information</h6>
                </div>
                <span v-if="order.typeShipping === 0">None</span>
                <span v-else-if="order.typeShipping === 1">Ships to School</span>
                <span v-else-if="[2, 3].includes(order.typeShipping)">
                  {{ order.shippingFname }} {{ order.shippingLname }}<br>
                  {{ order.shippingAddress }} {{ order.shippingAddress2 }}<br>
                  {{ order.shippingCity }}, {{ order.shippingState }} {{ order.shippingZip }}
                </span>

                <div class="heading_s1 mt-3 mb-0">
                  <h6>Order Date</h6>
                </div>
                {{ new Date(order.createdAt) | dateFormat('MM/DD/YYYY') }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-responsive shop_cart_table">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="product-thumbnail">&nbsp;</th>
                      <th class="product-name" style="text-align: center">Product</th>
                      <th class="product-student">Student</th>
                      <th class="product-price">Price</th>
                      <th class="product-quantity" style="text-align: center">Quantity</th>
                      <th class="product-subtotal">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in orderItems" :key="idx">
                      <td class="product-thumbnail">
                        <img v-if="item.ad" :src="item.ad.uriPreview" :alt="`product${idx}`">
                        <img v-else :src="productImageUri(item.product)" :alt="`product${idx}`">
                      </td>
                      <td data-title="Product">
                        {{ item.productName }}
                        <p v-if="item.bPersonalized" class="item-message-p" style="line-height: 18px;">
                          <strong>
                            {{ item.typePersonalization | personalizationTypeFilter }}
                          </strong>
                          <br>
                          <strong>
                            {{ item.studentPersonalizedName || `${item.studentFname} ${item.studentLname}` }}
                          </strong>
                        </p>
                        <p v-if="item.message" class="item-message-p"><strong>{{ item.message }}</strong></p>
                      </td>
                      <td class="product-student" data-title="Student">
                        <span v-if="item.bDonated">Donated</span>
                        <span v-else>{{ item.studentFname }} {{ item.studentLname }}</span>
                      </td>
                      <td class="product-price" data-title="Price">${{ Number(item.productTotalPrice).toFixed(2) }}</td>
                      <td class="product-quantity" data-title="Quantity">
                        <div class="quantity">
                          {{ item.quantity }}
                        </div>
                      </td>
                      <td class="product-subtotal" data-title="Total">${{ (item.quantity * Number(item.productTotalPrice)).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="7" class="px-0"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="medium_divider"></div>
              <div class="divider center_icon"><i class="ti-shopping-cart-full"></i></div>
              <div class="medium_divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 offset-md-6">
              <div class="border p-3 p-md-4">
                <div class="heading_s1 mb-3">
                  <h6>Summary</h6>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr v-if="!usedDiscount">
                        <td class="cart_total_label">Subtotal</td>
                        <td class="cart_total_amount">
                          <strong v-if="store.bParentsPayConvenienceFee">
                            <span v-if="store.bCollectSalesTax">
                              ${{ (Number(order.total) - Number(order.calculatedConvenienceFee) - Number(order.dedicationAdFee) - Number(order.amountShipping) - Number(order.amountSalesTax)).toFixed(2) }}
                            </span>
                            <span v-else>
                              ${{ (Number(order.total) - Number(order.calculatedConvenienceFee) - Number(order.dedicationAdFee) - Number(order.amountShipping)).toFixed(2) }}
                            </span>
                          </strong>
                          <strong v-else-if="store.bCollectSalesTax">
                            ${{ (Number(order.total) - Number(order.amountShipping) - Number(order.amountSalesTax)).toFixed(2) }}
                          </strong>
                          <strong v-else>
                            ${{ Number(order.total - Number(order.amountShipping)).toFixed(2) }}
                          </strong>
                        </td>
                      </tr>
                      <tr v-if="usedDiscount">
                        <td class="cart_total_label">Discount</td>
                        <td class="cart_total_amount">
                          <span v-if="usedDiscount.typeDiscount === 1">-{{ Number(usedDiscount.amount).toFixed(0) }}%</span>
                          <span v-else>-${{ Number(usedDiscount.amount).toFixed(2) }}</span>
                        </td>
                      </tr>
                      <tr v-if="[2, 3].includes(order.typeShipping) && Number(order.amountShipping) > 0 && (Number(order.total) > Number(order.amountShipping))">
                        <td class="cart_total_label">Shipping</td>
                        <td class="cart_total_amount">+${{ Number(order.amountShipping).toFixed(2) }}</td>
                      </tr>
                      <template v-if="account.parentId === '87PNV2'">
                        <tr v-if="store.bParentsPayConvenienceFee && Number(order.total) > 0 && (Number(order.calculatedConvenienceFee) > 0 || Number(order.dedicationAdFee) > 0)">
                          <td class="cart_total_label">Convenience fee</td>
                          <td class="cart_total_amount">+${{ (Number(order.calculatedConvenienceFee) + Number(order.dedicationAdFee)).toFixed(2) }}</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr v-if="store.bParentsPayConvenienceFee && Number(order.total) > 0 && Number(order.calculatedConvenienceFee) > 0">
                          <td class="cart_total_label">Convenience fee</td>
                          <td class="cart_total_amount">+${{ Number(order.calculatedConvenienceFee).toFixed(2) }}</td>
                        </tr>
                        <tr v-if="store.bParentsPayConvenienceFee && Number(order.total) > 0 && Number(order.dedicationAdFee) > 0">
                          <td class="cart_total_label">Ad fee</td>
                          <td class="cart_total_amount">+${{ Number(order.dedicationAdFee).toFixed(2) }}</td>
                        </tr>
                      </template>
                      <tr v-if="store.bCollectSalesTax && Number(order.amountSalesTax) > 0 && Number(order.total) > 0">
                        <td class="cart_total_label">Sales tax</td>
                        <td class="cart_total_amount">+${{ Number(order.amountSalesTax).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td class="cart_total_label">Total</td>
                        <td class="cart_total_amount"><strong>${{ Number(order.total).toFixed(2) }}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 offset-md-6 mt-5" style="text-align: right;">
              <p>Thank you for your order from {{ store.name }}!
              <p>If you have any questions, please click the button in the lower right.</p>
              <span>Thank you,</span><br>
              <span>{{ account.name }}</span><br>
              <small>powered by Yearbook Market</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.item-message-p {
  font-size: 12px;
  margin: 1px;
}
</style>

<script>
import to                       from '@/utils/to'
import { mapGetters }           from 'vuex'
import { storeCommonMixin }     from '@/mixins/storeCommon'
import Breadcrumbs              from '@/components/layout/header/Breadcrumbs'
import Repository               from '@/repositories/RepositoryFactory'
const OnlineOrderRepository     = Repository.get('online_orders')
const OnlineOrderItemRepository = Repository.get('online_order_items')
let $ = window.$

export default {
  name: 'Cart',
  mixins: [storeCommonMixin],
  components: { Breadcrumbs },
  data () {
    return {
      order        : {},
      orderItems   : [],
      usedDiscount : false
    }
  },
  computed: {
    ...mapGetters([
      'account',
      'store',
      'discount'
    ])
  },
  methods: {
    /**
     * Gets order receipt by transaction ID
     * @param transactionId - Stripe transaction ID
     */
    getOrderReceipt: async function (transactionId, orderId) {
      let err, order;
      [err, order] = await to(OnlineOrderRepository.get({ transactionId: transactionId }))
      if (!order) {
        throw new Error(err)
      } else if (order.data.length === 0 && orderId) {
        // Try to load by order ID if transaction ID failed to save
        [err, order] = await to(OnlineOrderRepository.get({ id: orderId, storeId: this.store.id }))
      }
      this.order = order.data[0]
      let onlineOrderItems;
      [err, onlineOrderItems] = await to(OnlineOrderItemRepository.get({ onlineOrderId: this.order.id, include: ['product', 'dedication_ad'] }))
      if (!onlineOrderItems) throw new Error(err)
      this.orderItems = onlineOrderItems.data
      this.hideLoader($)
    }
  },
  created () {
    this.showLoader($)
  },
  mounted () {
    this.usedDiscount = this.discount
    this.$store.dispatch('resetCartState')
    this.getOrderReceipt(this.$route.params.transactionId, this.$route.params.orderId)
  }
}
</script>
